<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="helper.provinces"
      resource=""
      @click-clear-filter="clearFilter"
      creatable
      @click-create="showModalCreate = true"
    >
      <template #append-actions="{ item }">
        <CCol class="d-flex px-2" col="12">
          <TButtonDelete @click="removeProvince(item.id)" />
          <TButtonShow @click="clickShowDistrict(item)" />
        </CCol>
      </template>
      <template #name="{ item }">
        <td>
          <TMessageText
            :value="item.name"
            noTranslate
            editable
            @change="
              $store.dispatch('helper.provinces.update', {
                id: item.id,
                attributes: { name: $event },
              })
            "
          />
        </td>
      </template>

      <template #country="{ item }">
        <td>
          <TMessage :content="item.country_id" noTranslate />
        </td>
      </template>

      <template #id-filter>
        <TInputText :value.sync="filter.id" @update:value="filterChange" />
      </template>

      <template #name-filter>
        <TInputText :value.sync="filter.name" @update:value="filterChange" />
      </template>

      <template #country-filter>
        <TInputText
          :value.sync="filter.country_id"
          @update:value="filterChange"
        />
      </template>
    </TTableAdvance>
    <SModalCreateAddress :show.sync="showModalCreate" />

    <SModalShowDistrict :show.sync="showModalDistrict" :id.sync="getId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Province id", _style: "min-width: 150px" },
        {
          key: "name",
          label: "Province",
          _style: "min-width: 350px",
        },

        {
          key: "country",
          label: "Country",
          _style: "min-width: 350px",
        },
      ],

      showModalCreate: false,
      showModalDistrict: false,

      filter: {},
    };
  },
  mounted() {
    this.$store.dispatch("helper.provinces.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "helper.provinces.list",
    }),

    getId() {
      return this.$route.query.provinces;
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch("helper.provinces.fetch");
    },

    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy({
        ...filter,
      });
      this.$store.dispatch("helper.provinces.apply-query", filterFields);
    },

    clearFilter() {
      this.filter = {};
      this.filterChange();
    },

    clickShowDistrict(item) {
      this.$router.push({ query: { provinces: item.id } });
      this.showModalDistrict = true;
    },

    removeProvince(id) {
      this.$store.dispatch("helper.provinces.delete", id);
    },
  },
};
</script>
